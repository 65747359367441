import React, {useState} from "react";
import {Form, Card, notification, Row, Col, Button, Input, Select, Radio} from 'antd';
import jsonfile from './article.json'
import {CisUI} from '../../../util/CISUI'
import InputFields from '../../../util/InputField'
import 'react-smart-data-table/dist/react-smart-data-table.css'
import Config from "../../../util/config";
import axios from "../../../util/Api";
import {useHistory} from "react-router-dom";
import RemoteJson from "../../../util/gerremotedata";
import IntlMessages from "../../../util/IntlMessages";
import SelectData from "../../../util/selectdata";
import CKEditor from "react-ckeditor-component";

const {Option} = Select;

const NewArticle = (props) => {

    const [images, setImages] = useState([]);
    const [ck, setCK] = useState("");
    const [state, setState] = useState({
        sub_modules: [],
        menus: [],
        actions: [],
        disabled: 1,
        required: 0
    });

    const userID = props.match.params.id;
    let userData = [];
    // console.log("Userid : "+props.match.params.id);
    let modules = SelectData("getModules");
    // console.log(modules);


    const getSubModule = (id) => {
        axios.get(Config.apiserver + 'getSubModules/' + id, CisUI.HeaderRequest)
            .then(res => {
                if (res.data.status === 1) {
                    setState({
                        disabled: 0,
                        required: 1,
                        sub_modules: res.data.sub_modules,
                        menus: res.data.menus,
                        actions: res.data.actions
                    });
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    const handleChange = (evt) => {
        // console.log(evt.editor);
        var newContent = evt.editor.getData();
        setCK(newContent)
    }

    let isEdit = 0;
    if (userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    let endpoint = jsonfile.urls.add;
    let redirectto = "../" + jsonfile.urls.list;
    if (isEdit === 1) {
        userData = RemoteJson(jsonfile.urls.view + "/" + userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit + "/" + userID;
        redirectto = "../../" + jsonfile.urls.list;
    }


    const [form] = Form.useForm();

    const history = useHistory();

    const changeImage = (e) => {
        let file = e.target.files;
        let img = [];
        file.forEach((item) => {
            // console.log(item)
            let reader = new FileReader();
            reader.onload = (e) => {
                img.push(e.target.result);
            };
            reader.readAsDataURL(item);
        })
        setImages(img);
    }

    const onFinish = (values) => {
        // setLoading({loading:true})
        // console.log("Input Data : "+values);

        const data = {
            ...values,
            details: btoa(ck),
            images: images
        }

        console.log(data);
        // document.getElementById("loader").style.display = "block";
        axios.post(Config.apiserver + endpoint, data)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    notification.success({
                        message: 'Success',
                        type: "success",
                        description: res.data.msg
                    });

                    history.push(redirectto);
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                // document.getElementById("loader").style.display = "none";
                console.log(errors);
            });
    };

    return (
        <Card title={<IntlMessages id={Title}/>} extra={
            isEdit === 1
                ? CisUI().addAction('../../' + jsonfile.urls.list)
                : CisUI().addAction('../' + jsonfile.urls.list)
        }>
            {CisUI().showLoading}
            <Form
                form={form}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >
                {/*{InputFields(jsonfile.input,userData,isEdit)}*/}

                <div className="row">
                    <Row className="ant-row">

                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="module_id"
                                    label="Module"
                                    rules={[{
                                        required: true,
                                        message: "Module is required"
                                    }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a module"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        onChange={getSubModule}
                                    >
                                        {
                                            modules.map((items, index) =>
                                                <Option key={++index} value={items.mdl_id}>{items.mdl_name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="submodule_id"
                                    label="Sub Module"
                                    rules={[{
                                        required: state.required,
                                        message: "Sub Module is required"
                                    }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a sub module"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        disabled={state.disabled}
                                    >
                                        {
                                            state.sub_modules.map((items, index) =>
                                                <Option key={++index} value={items.sbl_id}>{items.sbl_name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="action_id"
                                    label="Action"
                                    rules={[{
                                        required: state.required,
                                        message: "Action is required"
                                    }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a Action"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        disabled={state.disabled}
                                    >
                                        {
                                            state.actions.map((items, index) =>
                                                <Option key={++index} value={items.act_id}>{items.act_name}</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="title"
                                    label="Article Title"
                                >
                                    <Input placeholder="Article Title"/>
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="video_link"
                                    label="Video Link"
                                >
                                    <Input placeholder="Video Link"/>
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="image"
                                    label="Image"
                                >
                                    <input type="file" multiple onChange={changeImage}/>
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={24} md={24} sm={24} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="details"
                                    label="Details"
                                >
                                    <CKEditor
                                        activeClass="p10"
                                        content={ck}
                                        events={{
                                            "change": handleChange
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </Col>

                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className="gx-form-row0">
                                <Form.Item
                                    name="status"
                                >
                                    {/*<Select*/}
                                    {/*    showSearch*/}
                                    {/*    placeholder="Select a Status"*/}
                                    {/*    optionFilterProp="children"*/}
                                    {/*    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}*/}
                                    {/*>*/}
                                    {/*    <Option value="1">Active</Option>*/}
                                    {/*    <Option value="0">Inactive</Option>*/}

                                    {/*</Select>*/}
                                    <Radio.Group style={{marginTop: "27px"}}>
                                        <Radio value={1}>Publish</Radio>
                                        <Radio value={0}>Save As Draft</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>

                </div>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center'}}>
                        <Button type="primary" htmlType="submit" name="publish" value="1">
                            Submit
                        </Button>
                        <a className="ant-btn ant-alert-info" onClick={() => history.goBack()}>Cancel</a>
                    </Col>
                </Row>

            </Form>
        </Card>
    );
};

export default NewArticle;
