import React, { useState, useEffect } from "react";
import { Breadcrumb, Input, Layout, Card, Menu, Button, Dropdown, Modal, notification } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import { Table } from "antd";
import axios from "axios";

import { CisUI } from "../../../util/CISUI";
import config from "../../../util/config";
import { EllipsisOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";

const columns = [
    {
        title: "SL",
        dataIndex: "sl",
        key: "sl",
    },
    {
        title: "Module Name",
        dataIndex: "module_name",
        key: "module_name",
    },
    {
        title: "Submodule Name",
        dataIndex: "submodule_name",
        key: "submodule_name",
    },
    {
        title: "Title",
        dataIndex: "title",
        key: "title",
    },
    {
        title: "Action Name",
        dataIndex: "action_name",
        key: "action_name",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
    },
    {
        title: "Last Updated",
        dataIndex: "update",
        key: "update",
    },
    {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
    },
];

const ArticleList = () => {
    const [tableData, setTableData] = useState([]);
    const [articles, setArticle] = useState([]);
    const publishDate = (orgianlDate) => {
        var date = new Date(orgianlDate);
        date = date.toDateString();
        return date;
    }

    const handleSearch = ((e) => {
        console.log(e.target.value)

        const salesproduct = [];

        articles.map((article, i) => {
            if (article.submodule_name.toLowerCase().includes(e.target.value.toLowerCase()) || article.title.toLowerCase().includes(e.target.value.toLowerCase())) {
                salesproduct.push({
                    key: article.id,
                    sl: ++i,
                    module_name: article.module_name,
                    submodule_name: article.submodule_name,
                    title: article.title,
                    action_name: article.action_name,
                    status: CisUI().getTicketStatus(article.status),
                    update: publishDate(article.created_at),
                    actions: (
                        <Dropdown overlay={actionMenu(article.id)} placement="bottomCenter">
                            <Button className="list-table-btn" size="small">
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    ),
                });
            }
        })
        setTableData(salesproduct)
    });


    const getTableData = () => {
        const data = [];

        articles.map((article, i) => {
            console.log("article.updated_at", article.updated_at)
            data.push({
                key: article.id,
                sl: ++i,
                module_name: article.module_name,
                submodule_name: article.submodule_name,
                title: article.title,
                action_name: CisUI().actionView(article.action_name),
                status: CisUI().getStatus(article.status),
                update: publishDate(article.updated_at == null ? article.created_at : article.updated_at),
                actions: (
                    <Dropdown overlay={actionMenu(article.id)} placement="bottomCenter">
                        <Button className="list-table-btn" size="small">
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                ),
            });
        });
        setTableData(data)
    }

    useEffect(() => {
        getTableData();
    }, [articles]);

    const userData = JSON.parse(CisUI().getLocalUserData());
    let userArea = userData['area'];
    const getUser = userArea == 1 ? "admin" : "customer";

    const actionMenu = (id) => {
        const menu = (
            <Menu>
                <Menu.Item>
					<Link to={`/${getUser}/knowledge/article-edit/${id}`}>
						<Button
							size="small"
							type="primary"
							className="list-table-btn"
							block
						>
							Edit
						</Button>
					</Link>
				</Menu.Item>
                {
                    userArea == 1 && (
                        <Menu.Item key="3">
                            <Button
                                size="small"
                                type="primary"
                                className="list-table-btn"
                                style={{ backgroundColor: 'red' }}
                                block
                                onClick={() => {
                                    showConfirm(id);
                                }}
                            >
                                Delete
                            </Button>
                        </Menu.Item>
                    )
                }

            </Menu>
        );
        return menu;
    };

    const getTickets = () => {
        axios
            .get(
                config.apiserver + "knowledge/supportarticles",
                CisUI().HeaderRequest
            )
            .then((res) => {
                console.log("knowledge", res.data.data);
                setArticle(res.data.data)
            })
            .catch((errors) => console.log(errors));
    };

    function showConfirm(articleID) {
        Modal.confirm({
            title: "Do you Want to delete these items?",
            content: "If you delete the item it will be lost permanently.",
            okText: "Ok",
            cancelText: "Cancel",
            onOk() {
                axios
                    .delete(
                        config.apiserver +
                        `knowledge/supportarticledelete/${articleID}`,
                        CisUI().HeaderRequest
                    )
                    .then((res) => {
                        if (res.data.status == 1) {
                            // console.log("Making Request");

                            setArticle(articles.filter((article) => article.id !== articleID));
                            notification.success({
                                message: "Success",
                                type: "success",
                                description: res.data.msg,
                            });
                        } else {
                            // console.log("error");

                            notification.error({
                                message: "Error",
                                type: "error",
                                description: res.data.msg,
                            });
                        }
                    })
                    .catch((errors) => console.log(errors));
            },
            onCancel() {
                console.log("Cancel");
            },
        });
        console.log(articleID);
    }

    useEffect(() => {
        getTickets();
    }, []);

    console.log("tableData", tableData)

    return (
        <main className="ant-layout-content gx-layout-content gx-container-wrap ">
            <div className="home-main-content">
                <Layout className="ant-layout-has-sider gx-algolia-layout-has-sider">

                    <div className="gx-algolia-main">
                        <h1 className="home-top-header">Articles List<Link to="./create-articles"><Button className="title-btn" icon={<PlusOutlined />} /></Link></h1>

                        <Content className="gx-algolia-content">
                            <div className="gx-algolia-content-inner">
                                <Card className="tickets-card">
                                    <Card
                                        type="inner"

                                        extra={
                                            <Input onChange={handleSearch} size="large" placeholder="Search Ticket" addonAfter={<SearchOutlined />} />
                                        }
                                    >
                                        <Table scroll={{ x: true }} columns={columns} dataSource={tableData}/>
                                    </Card>
                                </Card>
                            </div>
                        </Content>
                    </div>
                </Layout>
            </div>
        </main>
    );
};

export default ArticleList;
