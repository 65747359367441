import React, {useEffect} from "react";
import {Button, Row, Checkbox, Col, Form, Input, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";

import {
    hideMessage,
    userSignIn,
} from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
import InfoView from "../components/InfoView";
import {CisUI} from "../util/CISUI";

const SignIn = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const authUser = useSelector(({auth}) => auth.authUser);

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    // const userData = JSON.parse(CisUI().getLocalUserData());
    // let userArea = userData['area'];
    // const getUser = userArea == 1 ? "admin" : "customer";

    const onFinish = values => {
        dispatch(userSignIn(values));
    };

    useEffect(() => {
        if (authUser !== null) {
            // history.push(`${getUser}/knowledge`);
            // console.log("authUser", authUser.user_roles)
            history.push((authUser.user_roles == 1 ? "admin" : "customer")+"/dashboard");
        }
    }, [authUser]);


    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-logo-content">
                        <div className="gx-app-logo-content-bg">
                            <img src={"https://via.placeholder.com/272x395"} alt='Neature'/>
                        </div>
                        <div className="gx-app-logo-wid">
                            <h1 className="color-black">Yotta SME Support Center!</h1>
                            <p className="color-black">To sign-up, contact us directly at:</p>

                            <div className="contact-info">
                                <p className="color-black"><i className="fa fa-envelope"/> Email: support@yottasme.com</p>
                                <p className="color-black"><i className="fa fa-phone"/> Phone: +880 1711 26100</p>
                            </div>

                            <p className="color-black">For login, use your YottaSME software credentials.</p>
                        </div>

                    </div>

                    <div className="gx-app-login-content">

                        <Form
                            initialValues={{remember: true}}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            className="gx-signin-form gx-form-row0">

                            <Row align="middle">
                                <Col md={6}/>
                                <Col md={12}>
                                    <div className="gx-app-logo" style={{ marginBottom: "34px"}}>
                                        <img alt="example" src="/assets/images/yottasme_logo.svg"/>
                                    </div>
                                </Col>
                                <Col md={6}/>
                            </Row>

                            <Col span={24}>
                                <Form.Item
                                    rules={[{required: true, message: 'The input is not valid E-mail!'}]} name="email">
                                    <Input placeholder="User Name"/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    rules={[{required: true, message: 'Please input your Password!'}]} name="password">
                                    <Input type="password" placeholder="Password"/>
                                </Form.Item>
                            </Col>
                            <Col span={24} offset={17}>
                                <Form.Item>
                                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                                        <IntlMessages id="app.userAuth.signIn"/>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Form>
                    </div>
                    <InfoView />
                </div>
            </div>
        </div>
    );
};

export default SignIn;
