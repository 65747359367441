module.exports = {
  footerText: 'Copyright CIS Tech Ltd © 2023',

  // baseurl: 'http://localhost:3001/',
  // apiserver: 'http://localhost:8001/api/',

  // baseurl: 'http://ciscustomercare.yottaerp.com',
  // apiserver: 'https://ciscustomercareapi.yottaerp.com/api/',

  baseurl: 'https://support.yottasme.com',
  apiserver: 'https://supportapi.yottasme.com/api/',

  profile_img: 'https://supportapi.yottasme.com/public/upload/profile_image/',
  ticket_file: 'https://supportapi.yottasme.com/public/upload/support/',
  cislogo: 'https://support.yottasme.com/public/assets/images/icon.png',
  // cislogo: 'http://localhost:3000/public/assets/images/icon.png',
  // ticket_file: 'http://localhost:8000/upload/support/',



  logourl : '/assets/images/coplogo.png',
  print_logourl : '/assets/images/cop_nlogo.png',
  company_name : 'CIS Tech Ltd',
  address : 'Parliament View, Plot-5/9, Block-A, Lalmatia,\n' +
      'Dhaka, Bangladesh',
  city : 'Dhaka',
  zipcode : '1206',
  phone : "+8801670228961",
  email : "info@cistechltd.com",
  website : "https://cistechltd.com"
}
