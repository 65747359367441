import React from "react";
import { Route, Switch } from "react-router-dom";

import CreateArticles from "../../../containers/Knowledge/Article/NewArticle";
import ArticleList from "../../../containers/Knowledge/Article/ArticleList";
import ArticleEdit from "../../../containers/Knowledge/Article/EditArticle";

const Knowledge = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}/create-articles`} component={CreateArticles} />
      <Route path={`${match.url}/article-list`} component={ArticleList} />
      <Route path={`${match.url}/article-edit/:id`} component={ArticleEdit} />
    </Switch>
  </div>
);

export default Knowledge;
